<template>
    <vs-popup class="machineAdd_popup" title="Ajouter machine" :active.sync="popupActive">
        <div class="vx-col w-full">
            <vs-input class="w-full" label="Nom machine" v-model="machine"/>
        </div>
        <br/>
        <div class="vx-col w-full">
            <vs-input class="w-full" label="Nom du groupe" v-model="groupe"/>
        </div>
        <br/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div></div>
                <vs-button color="success" type="filled" v-on:click="add()" icon-pack="feather" icon="icon-plus">Ajouter</vs-button>
            </vs-col>
        </vs-row>
    </vs-popup>
</template>

<style lang="scss">
.machineAdd_popup
{

}
</style>

<script>

import Machine  from '@/database/models/machine';

export default {
    components:{
	},
    props: {
    },
    data(){
        return{
            popupActive:false,
            callback:null,

            machine:'',
            groupe:'',
        }
    },
    watch:{
        $route (){
        },
    },
    beforeMount:function(){
        
    },
    methods:{
        openPopup( data, callback )
        {
            this.callback    = null
            this.popupActive = true

            if(callback)
                this.callback = callback

        },
        add(){
            if(this.machine.length<2)
                return alert('Nom machine trop court !')

            Machine.add({
                name   : this.machine,
                groupe : this.groupe,
                active : 1,
                admin  : 1,
            })
            .then(()=>{
                alert( this.machine+' ajouté.')
                if(this.callback)
                    this.callback()
            })
            this.popupActive = false
        },
    }
}

</script>